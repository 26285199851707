@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @apply text-gray-100;

  scroll-behavior: smooth;
  background-color: #1e293b;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.btn {
  @apply rounded-lg py-2 px-4 text-white shadow shadow-black/10 transition duration-150 ease-in-out;
  user-select: none;
}

.btn:focus {
  @apply outline-none ring;
}

.btn:disabled {
  @apply cursor-not-allowed opacity-50;
}

.btn-indigo {
  @apply bg-gradient-to-b from-indigo-600 to-indigo-700;
}

.btn-indigo:hover {
  @apply from-indigo-500 to-indigo-600;
}

.btn-indigo:focus {
  @apply from-indigo-600 to-indigo-700;
}

.btn-indigo:active {
  @apply bg-indigo-700;
}

.btn-gray {
  @apply bg-gray-700;
}

.btn-gray:hover {
  @apply bg-gray-600;
}

.btn-gray:focus {
  @apply border-gray-800;
}

.btn-gray:active {
  @apply bg-gray-800;
}

.btn-green {
  @apply bg-gradient-to-b from-green-500 to-green-600 shadow-lg shadow-green-800/60;
}

.btn-green:hover {
  @apply from-green-400 to-green-500;
}

.btn-green:active {
  @apply from-green-600 to-green-700;
}

.btn-green:focus {
  @apply border-green-300;
}

.btn-primary {
  @apply bg-gradient-to-b from-primary-600 to-primary-700 font-medium shadow-lg shadow-black/30;
}

.btn-primary:hover {
  @apply from-primary-500 to-primary-600;
}

.btn-primary:active {
  @apply from-primary-700 to-primary-800;
}

.btn-primary:focus {
  @apply border border-primary-800 bg-primary-600;
}

.btn-secondary {
  @apply bg-gradient-to-b from-secondary-600 to-secondary-700 font-medium shadow-lg shadow-black/30;
}

.btn-secondary:hover {
  @apply from-secondary-500 to-secondary-600;
}

.btn-secondary:active {
  @apply from-secondary-700 to-secondary-800;
}

.btn-secondary:focus {
  @apply border border-secondary-800 bg-secondary-600;
}

.btn-base {
  @apply rounded-3xl bg-gradient-to-b from-base-600 to-base-700 px-4 shadow-lg shadow-black/30;
}

.btn-base:hover {
  @apply from-base-500 to-base-600;
}

.btn-base:active {
  @apply from-base-700 to-base-800;
}

.btn-base:focus {
  @apply border border-base-800 bg-base-600;
}

.btn-capsule {
  @apply rounded-full font-semibold uppercase tracking-wide;
}

.btn-small {
  @apply py-1 px-2 text-sm;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.08, 1.08, 1.08);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse-small {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.pulse-2 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1.4s;
}

.pulse-3 {
  animation-name: pulse-small;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes enter {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-enter {
  animation: enter 0.2s ease-out;
}

@keyframes leave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.animate-leave {
  animation: leave 0.15s ease-in forwards;
}

.rubik {
  font-family: var(--font-rubik), sans-serif;
}

.dm-sans {
  font-family: var(--font-dm-sans), sans-serif;
}

.bungee {
  font-family: var(--font-bungee), sans-serif;
}

.sacramento {
  font-family: "Sacramento", cursive;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.bg-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.puzzle-bg {
  background-repeat: repeat;
}

.space-bg {
  background-image: url("/space-bg.jpeg");
  background-size: 250px 250px;
}

.words-bg {
  background-image: url("/words-bg.jpeg");
  background-size: 480px 320px;
}

/* Wordle related styles */

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme("colors.white");
  --default-cell-border-color: theme("colors.black");
  --default-cell-text-color: theme("colors.black");
  --absent-cell-bg-color: theme("colors.slate.400");
  --correct-cell-bg-color: theme("colors.green.400");
  --present-cell-bg-color: theme("colors.yellow.400");
}

.dark {
  --default-cell-bg-color: theme("colors.slate.900");
  --default-cell-border-color: theme("colors.white");
  --default-cell-text-color: theme("colors.white");
  --absent-cell-bg-color: theme("colors.slate.700");
}

.high-contrast {
  --correct-cell-bg-color: theme("colors.orange.400");
  --present-cell-bg-color: theme("colors.cyan.400");
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  margin-bottom: 2%;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
}

.right-icons {
  display: flex;
}

.shine {
  background: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.2) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  -webkit-animation-name: placeholderShimmer;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}

.noselect {
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none; /* Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge*/
  user-select: none; /* Future-proof*/
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.form-field {
  @apply block rounded-md border border-indigo-600 bg-gray-100 py-2 px-3 text-gray-900 placeholder:text-gray-500 focus:z-10  focus:border-blue-300 disabled:border-slate-700 dark:bg-gray-900 dark:text-gray-100 dark:disabled:bg-slate-700  dark:disabled:text-gray-500;
}
